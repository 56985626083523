<template>
  <div class="big">
    <div class="roommain">
      <div class="roomDataNo_id" @click="copyID">
        <p v-if="roomdata" class="roomDataNo">
          游戏ID:{{ roomdata.info.game_id }}
        </p>
        <span class="roomDataNo_copu">复制</span>
      </div>
      <div class="battle__detail__content__header">
        <div
          class="battle__detail__content__back"
          @click="$router.push({ path: '/battleBox' })"
        >
          <div>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAYAAADafVyIAAABkUlEQVRIS7XUz4tOURgH8M9X+bViJRtlOYTyFyBDyR5ZSVlQFPmxexdsTJQUyf/AgoWMLGwsZDUb5MfGQkQRWSAdnenOdOc1b82ded+zud167vdzbuc5T/StUkpwEwewLcmH/pou7zVsdjXht3EMn7A1yecugf21s0BfeA3dmeTFUsLrt9NAX/gXjCeZWmr4NDDK8BmgHuiJZrfP8LLDzgt+4A0mk9TnnFX/4CvWdggdVFqxSZxO8mqmqAJ7cA+r8bhp0S7eGmzHQazHTxxOcr99yG3kXJKrXYSmUeoGL+IsfmF3kqftNt2Lqq7EmSTXuiINdAGX8Q6b+y/aftzFCpxKcmORyBPswNE5QLODNrIxyfuuSCllHx7g4X9Ag4xjF3pJ/i4CWNW077d5ga6B89WXUj5i3SiB71g+EqCUMtZMhKlRARM4j4mhA6WULXiOZRgbKtCE1/bcgEtJekMBSimbcAQnm5l2B4dqiw+6Bz0cX2C71mFX51Bdf3ClfX8GAdfrqFgg8Buv8Qi3krxtf/cP+gGXc7jwl28AAAAASUVORK5CYII="
              alt=""
            />
          </div>
          <div class="battle__detail__content__back__icon">返回</div>
        </div>
        <div class="battle__detail__content__caseinfo">
          <div class="battle__detail__content__caseinfo__left">
            <div
              @click="handle2"
              class="battle__detail__content__caseinfo__left__round"
            >
              <span v-if="roomdata">{{ roomdata.newpond.length }}</span> 回合
            </div>
            <div class="battle__detail__content__caseinfo__left__caseslist">
              <div
                v-for="(itembox, indexbox) in roomdata.newpond"
                :key="indexbox"
                :style="{ backgroundImage: 'url(' + itembox.back_logo + ')' }"
              >
                <img :src="itembox.logo" alt="" />
              </div>
            </div>
          </div>
          <div class="battle__detail__content__caseinfo__right">
            箱子总额 <span v-if="roomdata">$ {{ roomdata.info.coin }} </span>
          </div>
        </div>
      </div>

      <!-- !! -->
      <div class="boxmain">
        <div
          class="boxmain_user_data"
          v-for="(itemroom, indexroom) in roomsum"
          :key="indexroom"
          v-if="indexroom < roomdata.info.type"
        >
          <div class="boxmain_room1">
            <div class="boxmain_room1_tit" v-if="isstart">
              <div
                class="boxmain_room1_tit_1"
                v-if="indexroom + 1 <= roomdata.user.length && isstart && isend"
              >
                <i class="iconfont">&#xe715;</i>
                <p>准备</p>
              </div>
              <div
                class="boxmain_room1_tit_1 boxmain_room1_tit_1_back"
                v-if="indexroom + 1 > roomdata.user.length && isstart"
              >
                <i class="iconfont">&#xe614;</i>
                <p>等待</p>
                <span @click="joinroom()">加入</span>
              </div>
              <div
                class="boxmain_room1_tit_1"
                v-if="!isend && prid.indexOf(indexroom) != -1"
              >
                <p>获胜</p>
                <i style="font-size: 22px; font-weight: 500; color: #ffba79"
                  >$ {{ price[prid.indexOf(indexroom)] | numFilter }}</i
                >
              </div>
              <div
                v-if="!isend && prid.indexOf(indexroom) == -1"
                class="boxmain_room1_tit_1 boxmain_room1_tit_1_back"
              >
                <p>惜败</p>
                <!-- <i style="font-size: 22px;font-weight: 500;color: #ffba79;">$ 4.86</i> -->
              </div>
            </div>
            <!-- 动画 -->
            <div class="animation_big" v-else>
              <div v-if="istest">
                <div
                  class="animation"
                  v-if="roomdata.user[indexroom] && indexroom == 0"
                >
                  <div
                    class="animation_div"
                    v-for="(iteman, index) in tests1"
                    :key="index"
                  >
                    <img :src="iteman.logo" alt="" />
                  </div>
                </div>
                <div
                  class="animation"
                  v-if="roomdata.user[indexroom] && indexroom == 1"
                >
                  <div
                    class="animation_div"
                    v-for="(iteman2, index) in tests2"
                    :key="index"
                  >
                    <img :src="iteman2.logo" alt="" />
                  </div>
                </div>
                <div
                  class="animation"
                  v-if="roomdata.user[indexroom] && indexroom == 2"
                >
                  <div
                    class="animation_div"
                    v-for="(iteman3, index) in tests3"
                    :key="index"
                  >
                    <img :src="iteman3.logo" alt="" />
                  </div>
                </div>
                <div
                  class="animation"
                  v-if="roomdata.user[indexroom] && indexroom == 3"
                >
                  <div
                    class="animation_div"
                    v-for="(iteman4, index) in tests4"
                    :key="index"
                  >
                    <img :src="iteman4.logo" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="user" v-if="roomdata.user[indexroom]">
              <img :src="roomdata.user[indexroom].logo" alt="" />
              <div class="user_data">
                <p>{{ roomdata.user[indexroom].username }}</p>
                <!-- <span>123123</span> -->
              </div>
            </div>
            <div class="user user2" v-else></div>
          </div>

          <div class="reward">
            <div
              class="reward_ui"
              v-if="roomdata.user[indexroom] && indexroom == 0"
            >
              <div
                class="reward_li"
                v-for="(itempr, indexpr) in prize1"
                :key="indexpr"
              >
                <div
                  :style="{ backgroundImage: 'url(' + itempr.back_logo_x + ')' }"
                >
                  <img :src="itempr.logo" />
                </div>
                <p>{{ itempr.title }}</p>
                <span>$ {{ itempr.coin }}</span>
              </div>
            </div>

            <div
              class="reward_ui"
              v-if="roomdata.user[indexroom] && indexroom == 1"
            >
              <div
                class="reward_li"
                v-for="(itempr2, indexpr) in prize2"
                :key="indexpr"
              >
                <div
                  :style="{ backgroundImage: 'url(' + itempr2.back_logo_x + ')' }"
                >
                  <img :src="itempr2.logo" alt="" />
                </div>
                <p>{{ itempr2.title }}</p>
                <span>$ {{ itempr2.coin }}</span>
              </div>
            </div>

            <div
              class="reward_ui"
              v-if="roomdata.user[indexroom] && indexroom == 2"
            >
              <div
                class="reward_li"
                v-for="(itempr3, indexpr) in prize3"
                :key="indexpr"
              >
                <div
                  :style="{ backgroundImage: 'url(' + itempr3.back_logo_x + ')' }"
                >
                  <img :src="itempr3.logo" alt="" />
                </div>
                <p>{{ itempr3.title }}</p>
                <span>$ {{ itempr3.coin }}</span>
              </div>
            </div>

            <div
              class="reward_ui"
              v-if="roomdata.user[indexroom] && indexroom == 3"
            >
              <div
                class="reward_li"
                v-for="(itempr4, indexpr) in prize4"
                :key="indexpr"
              >
                <div
                  :style="{ backgroundImage: 'url(' + itempr4.back_logo_x + ')' }"
                >
                  <img :src="itempr4.logo" alt="" />
                </div>
                <p>{{ itempr4.title }}</p>
                <span>$ {{ itempr4.coin }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 复制 -->
    <input
      style="position: absolute; top: 0; left: 0; opacity: 0; z-index: -10"
      type="text"
      name=""
      id="input"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tests1: "", //图片
      tests2: "", //图片
      tests3: "", //图片
      tests4: "", //图片
      roomsum: "1234", //默认房间4个 根据传入的type参数来控制显示
      roomdata: "", //房子信息
      roomdataid: "", //房子id
      roomboxdata: [], //房间箱子奖品信息
      roomboxdatasum: {}, //房间箱子奖品信息
      isstart: true, //是否开始
      istest: false, //动画是否播放
      client_id: "", //websocket初始化id
      isend: false, //房间是否已经结束

      websock: null, //建立的连接
      lockReconnect: false, //是否真正建立连接
      timeout: 8 * 1000, // 5+1~2秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      testsocket: true, //离开断开

      //拿到开奖信息
      lotteryinformation: [], //开奖信息

      prize1: [], //奖品1
      prize2: [], //奖品2
      prize3: [], //奖品3
      prize4: [], //奖品4
      prizesum: [], //全部奖品
      prid: [], //获取id排名
      price: [], //价格

      input: "", //复制id
    };
  },
  created() {
    //页面刚进入时开启长连接
   
  },
  destroyed() {
    this.testsocket = false;
    //页面销毁时关闭长连接
    
  },
  mounted() {
    this.client_id = this.$store.state.client_id;
    this.getroomdata();
    // this.getroomBox()
  },
  computed: {
    socketData() {
      return this.$store.state.socketData; //需要监听的数据
    },
    clientId() {
      return this.$store.state.client_id;
    },
  },
  watch: {
    clientId(data, old) {
      console.log("绑定");
      this.client_id = data;
      this.bind();
    },
    socketData(data, old) {
      if (data.type == "against_add") {
        //判断是否本房间
        if (data.data.info.id != this.roomdata.info.id) {
          return;
        }
        let arr = [];
        let lengt = data.data.info.pond_id.split(",");
        this.roomdataid = lengt;
        for (let i = 0; i < lengt.length; i++) {
          for (let j = 0; j < data.data.pond.length; j++) {
            if (lengt[i] == data.data.pond[j].id) {
              arr.push(data.data.pond[j]);
            }
          }
        }
        data.data.newpond = arr;
        this.roomdata = data.data;
      } else if (data.type == "against_clos") {
        //判断是否本房间
        if (data.id != this.roomdata.info.id) {
          return;
        }
        this.lotteryinformation = data.data;
        this.handle2();
      } else if (data.type == "against_suclos") {
        //判断是否本房间
        if (data.id != this.roomdata.info.id) {
          return;
        }
        this.prizesum = data.data;
      }
    },
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  methods: {
    //获取房子信息
    getroomdata() {
      this.$axios({
        url: "/worker/against/room/get",
        method: "post",
        data: {
          id: this.$route.query.id,
        },
      }).then((res) => {
        if (res.data.status === 200) {
          let arr = [];
          let lengt = res.data.data.info.pond_id.split(",");
          this.roomdataid = lengt;
          for (let i = 0; i < lengt.length; i++) {
            for (let j = 0; j < res.data.data.pond.length; j++) {
              if (lengt[i] == res.data.data.pond[j].id) {
                arr.push(res.data.data.pond[j]);
              }
            }
          }
          res.data.data.newpond = arr;
          this.roomdata = res.data.data;
          // console.log(this.roomdata);
          //处理状态
          if (res.data.data.info.type == res.data.data.user.length) {
            this.isstart = true;
          }
          if (res.data.data.win.length == 0) {
            this.isend = true;
          } else {
            this.isend = false;
          }

          this.prizesum = res.data.data.win;
          this.finalVictory();
          //获取每个箱子的奖品
          let id = Array.from(new Set(this.roomdataid));
          for (let i of id) {
            this.getroomBox(i);
          }
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "success",
          });
        }
      });
    },
    //处理最终胜利者
    finalVictory() {
      this.prize1 = [];
      this.prize2 = [];
      this.prize3 = [];
      this.prize4 = [];
      for (let i = 0; i < this.prizesum.length; i++) {
        for (let j = 0; j < this.roomdata.user.length; j++) {
          if (this.prizesum[i].uid == this.roomdata.user[j].uid) {
            this.prid.push(j);
            switch (j) {
              case 0:
                this.prize1 = this.prizesum[i].data;
                break;
              case 1:
                this.prize2 = this.prizesum[i].data;
                break;
              case 2:
                this.prize3 = this.prizesum[i].data;
                break;
              case 3:
                this.prize4 = this.prizesum[i].data;
                break;
            }
          }
        }
      }
      //处理总额
      var ix = 0;
      this.prizesum.forEach((element) => {
        this.price[ix] = 0;
        element["data"].forEach((element) => {
          this.price[ix] += parseFloat(element.coin);
        });
        ix++;
      });
      // console.log(this.price);
      // // for(let i = 0;i < this.prizesum.length;i++){
      // //   for(let j = 0;j < this.prizesum[i].data.length;j++){
      // //     this.price[i] += this.prizesum[i].data[j].coin
      // //   }
      // // }
    },
    //获取房间箱子信息
    getroomBox(id) {
      this.$axios({
        url: "/pond/Against/shop/List",
        method: "post",
        data: {
          p_id: id,
        },
      }).then((res) => {
        if (res.status === 200) {
          //判断数据是否大于15条inList
          this.roomboxdata = res.data;
          if (res.data.length < 25) {
            for (var i = 0; i < 35 - res.data.length; i++) {
              this.roomboxdata.push(res.data[i]);
            }
          }
          //打乱数组
          this.roomboxdata.sort(function() {
            return 0.5 - Math.random();
          });
          this.roomboxdatasum[id] = this.roomboxdata;
        }
      });
    },

    handle(i, a) {
      //处理中奖数据
      this.tests1 = this.roomboxdatasum[i].concat();
      //拿到中奖数据
      let jg = { logo: this.lotteryinformation[0].data[a - 1].logo };
      //赋值
      this.tests1.splice(13, 1, jg);

      //处理中奖数据
      this.tests2 = this.roomboxdatasum[i].concat();
      //拿到中奖数据
      let jg2 = { logo: this.lotteryinformation[1].data[a - 1].logo };
      //赋值
      this.tests2.splice(13, 1, jg2);

      if (this.roomdata.info.type >= 3) {
        //处理中奖数据
        this.tests3 = this.roomboxdatasum[i].concat();
        //拿到中奖数据
        let jg3 = { logo: this.lotteryinformation[2].data[a - 1].logo };
        //赋值
        this.tests3.splice(13, 1, jg3);
      }

      if (this.roomdata.info.type == 4) {
        //处理中奖数据
        this.tests4 = this.roomboxdatasum[i].concat();
        //拿到中奖数据
        let jg4 = { logo: this.lotteryinformation[3].data[a - 1].logo };
        //赋值
        this.tests4.splice(13, 1, jg4);
        // this.prize3.push(this.lotteryinformation[2].data[a-1])
        // this.prize4.push(this.lotteryinformation[3].data[a-1])
      }

      this.isstart = false;
      this.istest = true;
      setTimeout(() => {
        this.prize1.push(this.lotteryinformation[0].data[a - 1]);
        this.prize2.push(this.lotteryinformation[1].data[a - 1]);
        if (this.roomdata.info.type >= 3) {
          this.prize3.push(this.lotteryinformation[2].data[a - 1]);
        }
        if (this.roomdata.info.type == 4) {
          this.prize4.push(this.lotteryinformation[3].data[a - 1]);
        }
        this.istest = false;
      }, 4000);
    },
    handle2() {
      var that = this;
      var A = 0; //要循环的次数
      var t = 500; //第一次触发的时间
      var set1 = setInterval(fn, t);
      var time = [5000, 5000, 5000, 5000, 5000]; //计时器 可以每个不一样
      // var arr = [1,2,3] //传过去的参数
      function fn() {
        var t = time[A];
        //判断次数是否够了
        if (A >= that.roomdataid.length) {
          clearInterval(set1);
          that.test();
          return;
        }
        A++;
        that.handle(that.roomdataid[A - 1], A);
        //因为js是单线程 要清除再重新设定一次
        clearInterval(set1);
        set1 = setInterval(fn, t);
      }
    },
    test() {
      this.isstart = true;
      this.isend = false;
      this.finalVictory();
      // //抽奖金额
      // for(let i = 0;i < this.prizesum.length;i++){
      //   if(this.prizesum[i].uid == this.$store.state.userdata.uid){
      //     console.log(this.$store.state.userdata.uid);
      //     //处理金额
      //     let datapa = parseFloat(this.$store.state.userdata.coin) + parseFloat(this.price[i])
      //     datapa = datapa.toFixed(2)
      //     this.$store.commit("usercoin_up", datapa);
      //   }
      // }
    },
    //加入房间
    joinroom() {
      this.$axios({
        url: "/worker/against/room/add",
        method: "post",
        data: {
          client_id: this.client_id,
          id: this.$route.query.id,
        },
      }).then((res) => {
        if (res.data.status == 200) {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "success",
          });
          //扣除抽奖金额
          let datapa =
            parseFloat(this.$store.state.userdata.coin) -
            parseFloat(this.roomdata.info.coin);
          datapa = datapa.toFixed(2);
          this.$store.commit("usercoin_up", datapa);
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    //连接绑定
    bind() {
      if (!localStorage.getItem("token")) {
        return;
      }
      this.$axios({
        url: "/worker/user/bind",
        method: "post",
        data: {
          client_id: this.client_id,
        },
      }).then((res) => {
        if (res.status != 200) {
          this.$message({
            showClose: true,
            message: res.data.msg + "请刷新页面~~",
            type: "success",
          });
        }
      });
    },
    //复制游戏ID
    copyID() {
      var input = document.getElementById("input");
      // input.value = this.redData[0].share + '?code=' + this.$store.state.userdata.code; // 修改文本框的内容
      input.value = this.roomdata.info.game_id; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行浏览器复制命令
      this.$message({
        message: "复制成功",
        type: "success",
        showClose: true,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.big {
  margin: 10px auto;
  padding: 0 2%;
}
.roommain {
  width: 1200px;
  margin: 0 auto;
  padding: 28px 30px 30px 28px;
  background: #110b05;
}
.roomDataNo {
  text-align: right;
  line-height: 35px;
  font-weight: 400;
  color: #999;
}
.battle__detail__content__header {
  display: flex;
}
.battle__detail__content__back {
  cursor: pointer;
  width: 90px;
  height: 90px;
  background: #181008;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .battle__detail__content__back__icon {
    color: #fff;
    font-size: 14px;
    text-align: center;
    margin-top: 9px;
  }
}
.battle__detail__content__caseinfo {
  width: 1042px;
  height: 90px;
  background: #181008;
  padding: 0 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-left: 10px;
  .battle__detail__content__caseinfo__left {
    display: flex;
    align-items: center;
    margin-left: 32px;
  }
}
.battle__detail__content__caseinfo__left__round {
  color: #fff;
  font-size: 16px;
  span {
    font-size: 30px;
  }
}
.battle__detail__content__caseinfo__left__caseslist {
  display: flex;
  margin-left: 63px;
  div {
    margin-right: 34px;
    width: 84px;
    height: 68px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(https://api.img.cxskins.com/img/user/logo/20211130/a932eafd95f503ea98a0569dd23497de.png);
    position: relative;
  }
  img {
    position: absolute;
    width: 64px;
    height: 46px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
}
.battle__detail__content__caseinfo__right {
  font-size: 14px;
  font-weight: 700;
  color: #999;
  span {
    font-size: 30px;
    color: #ff9939;
  }
}

//!!
.boxmain {
  display: flex;
  margin: 10px 0;
  justify-content: space-evenly;
}
.boxmain_room1 {
  width: 278px;
  height: 208px;
  background: #181008;
  position: relative;
  overflow: hidden;
}
.boxmain_room1_tit {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 278px;
  height: 208px;
  .boxmain_room1_tit_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../assets/img/battleBox/battleDa.png");
    width: 166px;
    height: 166px;
    justify-content: center;
    position: relative;
    p {
      font-size: 16px;
      color: #fff;
    }
    i {
      font-size: 26px;
    }
    span {
      width: 80px;
      height: 38px;
      background: #261d14;
      cursor: pointer;
      border-radius: 10px;
      text-align: center;
      line-height: 38px;
      color: #fff;
      font-size: 14px;
      margin-top: 3px;
      position: absolute;
      bottom: 0px;
    }
  }
  .boxmain_room1_tit_1_back {
    background-image: url("../assets/img/battleBox/battleD.png");
  }
}

.user {
  width: 278px;
  height: 78px;
  background: linear-gradient(0deg, #211305, rgba(42, 29, 16, 0));
  border-bottom: 1px solid #ff9939;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 5px;
  }
  p {
    font-size: 14px;
  }
  span {
    color: #ff9939;
  }
}
.user2 {
  background: linear-gradient(0deg, #0f0e25, rgba(15, 14, 37, 0)) !important;
  border-bottom: 1px solid #5855d5 !important;
}
.reward {
  display: flex;
  flex-wrap: wrap;
  width: 278px;
}
.reward_ui {
  display: flex;
  flex-wrap: wrap;
}
.reward_li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 139px;
  height: 150px;
  border: 1px solid #573d25;
  div {
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // background-image: url(https://api.img.cxskins.com/img/user/logo/20211130/a932eafd95f503ea98a0569dd23497de.png);
    position: relative;
    img {
      position: absolute;
      width: 60px;
      height: 60px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;
    width: 100%;
    text-align: center;
  }
  span {
    color: #ffc11a;
  }
}

//动画
.animation_big {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../assets/img/battleBox/battleDa.png");
}
.animation {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-name: example;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  position: absolute;

  .animation_div {
    width: 278px;
    height: 208px;
    display: flex;
    justify-content: center;
    img {
      width: 88px;
      height: 88px;
    }
  }
}
@keyframes example {
  from {
    top: 0px;
  }
  to {
    top: -2645px;
  }
}
//游戏id
.roomDataNo_id {
  display: flex;
  position: relative;
  justify-content: flex-end;
  cursor: pointer;
}
.roomDataNo_id:hover {
  .roomDataNo_copu {
    display: block;
  }
}
.roomDataNo_copu {
  position: absolute;
  line-height: 35px;
  font-weight: 400;
  color: #fff;
  top: -20px;
  right: 0;
  display: none;
}
@media screen and (max-width: 800px) {
  .roommain {
    width: 100%;
    padding: 0;
  }
  .battle__detail__content__caseinfo {
    padding: 0;
    margin-left: 0;
  }
  .battle__detail__content__caseinfo__left {
    margin-left: 0 !important;
  }
  .battle__detail__content__back {
    padding: 10px;
  }
  .battle__detail__content__caseinfo__left__round {
    width: 60px;
  }
  .battle__detail__content__caseinfo__left__caseslist {
    margin-left: 13px;
    width: 130px;
    overflow-x: scroll;
    div {
      margin-right: 0px;
      img {
        position: static;
        margin-top: 10px;
      }
    }
  }
  .battle__detail__content__caseinfo__right {
    span {
      font-size: 22px;
    }
  }
  .boxmain {
    width: 100%;
    overflow-x: scroll;
    justify-content: flex-start;
    .boxmain_user_data {
      margin-right: 20px;
    }
  }
}
</style>
